import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Image from "gatsby-image"
import { FontAwesomeIcon  } from "@fortawesome/react-fontawesome"
import { graphql } from "gatsby"

export const pageQuery = graphql`
query {
  bannerImage: file(relativePath: { eq: "banner.png" }) {
    childImageSharp {
      fluid(maxWidth: 1600) {
        ...GatsbyImageSharpFluid
      }
    }
  }
  lsRetailLogo: file(relativePath: { eq: "lsretail.png" }) {
    childImageSharp {
      fluid(maxWidth: 300) {
        ...GatsbyImageSharpFluid
      }
    }
  }
  site {
    siteMetadata {
      title
    }
  }
}
`

const IndexPage = ({ data }) => (
  <Layout>
    <SEO title={data.site.siteMetadata.title} />
    <div className="container">
      <Image fluid={data.bannerImage.childImageSharp.fluid}/>
    </div>
    <div className="container my-5 my-sm-0 mt-sm-5">
      <div className="row">
        <div className="col-md-3 col-sm-6 mb-4 mb-md-0">
          <a href="#what" className="p-4 bg-primary text-white d-block h-100 text-center text-decoration-none">
            Was ist KassenSichV?
          </a>
        </div>
        <div className="col-md-3 col-sm-6 mb-4 mb-md-0">
          <a href="#offer" className="p-4 bg-primary text-white d-block h-100 text-center text-decoration-none">
            Was kann FS&nbsp;SOFT?
          </a>
        </div>
        <div className="col-md-3 col-sm-6 mb-4 mb-md-0">
          <a href="#price" className="p-4 bg-primary text-white d-block h-100 text-center text-decoration-none">
            Was kostet das?
          </a>
        </div>
        <div className="col-md-3 col-sm-6 mb-4 mb-md-0">
          <a href="#download" className="p-4 bg-primary text-white d-block h-100 text-center text-decoration-none">
            Downloads &amp; Partnerportal
          </a>
        </div>
      </div>
    </div>
    <section id="what" className="w-100 py-5">
      <div className="container">
        <h1 className="text-primary text-break">Deutsche Kas­sen­si­che­rungs­ver­ord­nung 2020</h1>
        
        <div className="row">
          <div className="col-md-8">
            <p>
              Seit dem 1.1.2020 ist die Kassensicherungsverordnung in Kraft.
              Das bedeutet sämtliche Kassen-, Abrechnungs-, Sicherungs- und Aufzeichnungssysteme müssen umgestellt bzw. mit entsprechenden Masßnahmen aufgerüstet werden.
            </p>
            <ul>
              <li>Jede Kassentransaktion muss elektronisch signiert werden</li>
              <li>Die Signatur muss mittels der TSE (Tech. Sicherheits Einrichtung) auf dem ein personalisiertes Zertifikat gespeichert ist, verschlüsselt werden.</li>
              <li>Die TSE muss in unmittelbarer Nähe der betreibenden Kasse installiert sein.</li>
              <li>Die Schnittstelle der Kasse zur Signaturerstellungseinheit darf nicht umgehbar sein</li>
              <li>Bereitstellung der DSFinV-K aus den Journaldaten</li>
              <li>Belegdrucker oder Vorrichtung zur elektronischen Übermittlung von Zahlungsbelegen</li>
              <li>Die Belegsignatur muss am Zahlungsbeleg ersichtlich sein, gewünscht ist ein elektronisch lesbarer Aufdruck (QR-Code)</li>
              <li>Eindeutige Kassenidentifikationsnummer, ausgegeben durch das BFI</li>
              <li>Verpflichtete Erstellung von Backups der Signaturinformationen und Journale</li>
              <li>Meldungen an das Finanzamt</li>
              <li>Einzelheiten der Kassensicherheitsverordnung können unter Kassen Sicherheits Verordung (SichV) beim BFI nachgelesen werden</li>
              <li>Bereitsstellung der DSFinV-K und der Signaturdaten bei einem Audit durch Organe des BFI</li>
            </ul>       
          </div>
        </div>
      </div>
    </section>
    <section id="offer" className="w-100 py-5 bg-light">
      <div className="container">
        <div className="row">
          <div className="col-md-8 order-md-1 order-sm-2">
            <h3 className="text-primary">FS SOFT ermöglicht die gesetzeskonforme Kasse!</h3>

            <h5 className="text-primary">KASSENSIGNATUR ADDON</h5>

            <p>Add-On für Dynamics NAV® / BC® mit LS Retail® um den Betrieb gemäß den Vorschriften des BFI zu ermöglichen und insbesondere die Vorschriften der KassenSichV zu erfüllen.</p>
            <p>
              Rundum Service der Funktionalität. 
              Im Funktionsumfang sind folgende Leistungen enthalten:
            </p>

            <ul className="fa-ul">
              <li><span className="fa-li text-primary"><FontAwesomeIcon icon="check"/></span>Anmeldung, Aktivierung der Kasse beim BFI</li>
              <li><span className="fa-li text-primary"><FontAwesomeIcon icon="check"/></span>Erstellung der Signatur</li>
              <li><span className="fa-li text-primary"><FontAwesomeIcon icon="check"/></span>Führung der Journaldatei für die Geschäftsvorgänge</li>
              <li><span className="fa-li text-primary"><FontAwesomeIcon icon="check"/></span>Sichere Aufbewahrung der Signatur- und Journaldaten über die Mindestdauer (derzeit 5 Jahre)</li>
              <li><span className="fa-li text-primary"><FontAwesomeIcon icon="check"/></span>Backup der Journaldaten</li>
              <li><span className="fa-li text-primary"><FontAwesomeIcon icon="check"/></span>Sichere, verschlüsselte Aufbewahrung im Cloudspeicher</li>
              <li><span className="fa-li text-primary"><FontAwesomeIcon icon="check"/></span>(Online) Bereitstellung der Signaturdaten und der DSFinV-K. Verschlüsselt, nur der Inhaber des Schlüssels hat Zugriff auf die Daten</li>
              <li><span className="fa-li text-primary"><FontAwesomeIcon icon="check"/></span>Automatische Meldung(en) der vorgeschriebenen Daten beim BFI</li>
            </ul>

            <p>Für den sicheren Betrieb von mehreren Kassen und/oder mehrerer Filialen stehen Signaturserver für das lokale Netzwerk oder in der Cloud zur Verfügung.</p>

            <p>Ein Portal für die Anmeldung zur Signierung und den Erwerb der TSE steht zur Verfügung.</p>

            <h5 className="text-primary">UNTERSTÜTZTE VERSIONEN:</h5>

            <p>Dynamics NAV 2009R2, 2013R2, 2015, 2016, 2017, 2018</p>

            <p>Dynamics 365 BC</p>

            <p>mit dem AddOn LS Retail</p> 

            <div style={{width: 200}}><Image fluid={data.lsRetailLogo.childImageSharp.fluid}/></div>
          </div>
          <div className="col-md-4 order-md-2 order-sm-1 mb-sm-5">
            <div className="card bg-primary text-white rounded-0">
              <div className="card-body">
                <h5 className="card-title">Vorteile im Überblick</h5>
                <ul>
                  <li>Erfüllung der KassenSichV ab 01.10.2020</li>
                  <li>Rechtsgültiger Beleg</li>
                  <li>Aufbewahrungspflicht erfüllt</li>
                  <li>Internationaler Standard <p className="small">Bereit: AT, DE, SL, CZ, CRO, FR, IT</p></li>
                  <li>Datenschutz durch End-to-End Verschlüsselung</li>
                  <li>Zugriff nur durch Inhaber</li>
                  <li>Einfaches Handling</li>
                  <li>Automatisierte Kasseneinrichtung</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section id="price" className="w-100 py-5">
      <div className="container">
        <div className="row">
          <div className="col-md-8 order-md-1 order-sm-2">
            <h3 className="text-primary">Preise</h3>
            <br />
            <h6 className="text-primary">ADDON FÜR DYNAMICS NAV® MIT LS RETAIL®</h6>
            <div className="row">
              <div className="col-8"><p>1. - 10. Kasse (pro Kasse)</p></div>
              <div className="col-4"><p>€ 280,--</p></div>
            </div>
            <div className="row">
              <div className="col-8"><p>11. - 25. Kasse (pro Kasse)</p></div>
              <div className="col-4"><p>€ 220,--</p></div>
            </div>
            <div className="row">
              <div className="col-8"><p>26 und mehr Kassen (pro Kasse)</p></div>
              <div className="col-4"><p>€ 190,--</p></div>
            </div>
            <p>Programmwartungsgebühr jährlich 16 % vom Lizenzwert.</p>
            <br />
            <h6 className="text-primary">HARDWARE SIGNATURSERVER (MICRO-PC)</h6>
            <p>(Zugriff mehrerer Kassen in einer Filiale)</p>
            <div className="row">
              <div className="col-8"><p>Kauf, einmalig</p></div>
              <div className="col-4"><p>€ 420,--</p></div>
            </div>
            <div className="row">
              <div className="col-8"><p>oder Miete inkl. Support und techn. Instandhaltung bzw. Tausch Vertrag mit dreijähriger Mindestlaufzeit, jährliche Mietgebühr</p></div>
              <div className="col-4"><p>€ 170,--</p></div>
            </div>
            <br />
            <h6 className="text-primary">TSE</h6>
            <p>Die TSE wird über unseren Partner POSBOX in Deutschland erhältlich sein.</p>
            <div className="row">
              <div className="col-8"><p>Kosten pro TSE (Swissbit)</p></div>
              <div className="col-4"><p>€ 199,--</p></div>
            </div>
            <div className="row">
              <div className="col-8"><p>Einmalige Einrichtungsgebühr</p></div>
              <div className="col-4"><p>€ 150,--</p></div>
            </div>
            <br />
            <h6 className="text-primary">SIGNIERSERVICE</h6>
            <div className="row">
              <div className="col-8"><p>Signierservice pro Kasse, pro Jahr inkl. Speicherung und Backup, autom. Meldung an das BFI Vertragsdauer 5 Jahre, Zahlung 1 x jährlich im Voraus, inkl.140.000 Transaktionen, das entspricht ~ 60.000 Belege</p></div>
              <div className="col-4"><p>€ 144,--</p></div>
            </div>
            <div className="row">
              <div className="col-8"><p>Gebühr für je weitere 140.000 Transaktionen, werden in einer Kasse 140.000 Transaktionen im lfd. Jahr erreicht, wird im Folgemonat die Gebührenerweiterung verrechnet.</p></div>
              <div className="col-4"><p>€ 97,90</p></div>
            </div>
            <p className="small">Alle Preise exkl. Ust.</p>
          </div>
          <div className="col-md-4 order-md-2 order-sm-1 mb-sm-5">
            <div className="card bg-primary text-white rounded-0">
              <div className="card-body">
                <h5 className="card-title">Signaturautomatik</h5>
                <ul>
                  <li>Sicherung <span className="small">und Backup in der Cloud</span></li>
                  <li>Aufbewahrung <span className="small">mindestens 5 Jahre</span></li>
                  <li>Automatische <span className="small">Anmeldung u. Aktivierung</span></li>
                  <li>Automatische <span className="small">BFI Meldung</span></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section id="download" className="w-100 py-5 bg-light">
      <div className="container">
        <div className="row">
          <div className="col-md-8">
            <h3 className="text-primary">Downloads</h3>
            <br />
            <div className="list-group">
              <a className="list-group-item list-group-item-action text-primary" href={`data/factsheet_kassenSichV.pdf`} download><FontAwesomeIcon icon="file-pdf" /> Factsheet FS SOFT</a>
              <a className="list-group-item list-group-item-action text-primary" href={`data/Anwendungserlass-Finanzministerium-19-7-2019.pdf`} download><FontAwesomeIcon icon="file-pdf" /> Anwendungserlass Finanzministerium</a>
              <a className="list-group-item list-group-item-action text-primary" href={`data/DSFinV-K-Vers-2-0.pdf`} download><FontAwesomeIcon icon="file-pdf" /> DSFinV-K</a>
              <a className="list-group-item list-group-item-action text-primary" href={`data/Nichtbeanstandungsregelung-bei-verwendung-elektronischer-aufzeichnungssysteme-2019-11-05.pdf`} download><FontAwesomeIcon icon="file-pdf" /> Nichtbeanstandungsregelung</a>
              <a className="list-group-item list-group-item-action text-primary" href="https://www.bundesfinanzministerium.de/Content/DE/Gesetzestexte/Gesetze_Verordnungen/2017-10-06-KassenSichV.html"><FontAwesomeIcon icon="link" /> Ver­ord­nung - Bundesministerium der Finanzen</a>
            </div>
            <br />
            <h3 className="text-primary">Partnerportal</h3>
            Hier geht es zum <a href="https://portal.fs-soft.at/">Partnerportal...</a>
          </div>
        </div>
      </div>
    </section>
  </Layout>
)

export default IndexPage
